export default {
  lms: {
    page_titles: {
      main: "Main",
      my_courses: "My courses",
      all_courses: "All courses",
      draft: "Drafts",
      archive: "Archive",
      categories: "Categories",
      administration: "Administration",
      course_new: "Create course",
      course_import: "Import course",
      category_new: "Create category",
      courses_empty: "There are no courses yet",
      categories_empty: "Categories haven't been created yet",
      help: "Help",
      statistics: "Statistics",
      familiarization_courses: "Courses to familiarization"
    },
    excel: {
      download_excel: "Excel",
      fail_export: "Fail export to excel",
    }
  },
  course: {
    fileName:"Файл",
    tabs: {
      description: "Description",
      structure: "Structure",
      content: "Content",
      lesson: "Lesson: {title}",
      module: "Module: {title}",
      familiarization: "Required to familiarize"
    },
    description_edit: {
      message: {
        error: {
          title: "",
          text: "Can't save changes"
        }
      }
    },
    import: {
      title: "Import course",
      file: "Course file",
      using_file: "File used",
      file_upload: "Upload file",
      file_update: "Replace file",
      scorm_file_info: "Upload a SCORM 1.2 or SCORM 2004 file no larger than {maxSize}MB.",
      header: "Error",
      mb: " Mb. ",
      wrong_mimes: "Invalid course format",
      file_required: "Select an archive with a course",
      file_size: "The file size must not exceed "
    },
    course_alert: "To display the course correctly, you need to allow your browser to use third-party cookies.",
    course_alert_link: "How to do this?",
    create: "Creating a course",
    title: "Course name",
    title_placeholder: "Enter the course name",
    categories: "Categories",
    categories_placeholder: "Select categories",
    category_no_found: "Category not found",
    description: "Course description",
    description_placeholder: "Enter description for the course",
    additionally: "Additionally",
    additionally_placeholder: "(course cover, status)",
    status_title: "Status",
    statuses: {
      draft: "Draft",
      published: "Published",
      archive: "Archive"
    },
    lesson_edit: {
      title: "Lesson name",
      content: "Lesson content",
      message: {
        error: {
          title: "Can't save lesson",
          text: "Lesson must have title and content"
        }
      }
    },
    modal_info: {
      save: "Save changes?",
      delete_module: "Delete module?",
      delete_lesson: "Delete lesson?"
    },
    button: {
      create: "Create a course",
      show: "View the course",
      start: "Start the course",
      continue: "Continue the course",
      go_to_course: "View the course",
      publish: "Publish",
      back: "Back",
      next: "Next",
      links: {
        archive: "Archive course",
        edit: "Edit course",
        delete: "Delete course"
      },
      continue_training: "Continue training",
      find_course: "Find a new course",
      familiarization_finish: "Confirm familiarization"
    },
    card: {
      author: "Author: ",
      categories: "Categories: ",
      added: "Added: ",
      duration: "Passage time: ",
      hours: "o'clock"
    },
    structure: {
      lesson: "Lesson",
      lesson_statuses: {
        new: "New",
        in_progress: "In progress",
        completed: "Completed"
      },
      add: {
        module: "add module",
        lesson: "add lesson",
        exam: "add exam"
      },
      placeholder: {
        module: "Enter module title",
        lesson: "Enter lesson title"
      },
      validation_error: "Title must be between 2 and 150 characters",
      message: {
        error: {
          title: "Can't save the structure",
          text: "There are modules without title or lessons"
        },
        template_missing: "To add an exam to a course, you must first create it and publish it to the testing service. Without this action, the exam will not be available for addition"
      }
    }
  },
  category: {
    create: "Creating a category",
    title: "Title",
    title_placeholder: "Enter the category title",
    description: "Category description",
    description_placeholder: "Enter description for the category",
    no_found: "Category not found",
    name: "Categories",
    courses: "Courses"
  },
  froala: {
    content_placeholder: "Enter text here",
    text: {
      code: "Code",
      highlighted: "Highlighted",
      transparent: "Transparent"
    },
    paragraph: {
      grey: "Grey",
      bordered: "Bordered",
      spaced: "Spaced",
      uppercase: "Uppercase"
    },
    link_styles: {
      green: "Green",
      strong: "Strong"
    }
  },
  popup:{
    congratulations: "Congratulate!",
    course: "Course ",
    completed: " completed",
    studied: "You have studied ",
    materials: " % course materials"
  },
  table: {
    search_placeholder: "Start typing your course name",
    created_at: "Start date",
    responsible: "Responsible",
    position: "Positions",
    department: "Department",
    course_title: "Course",
    completed_at: "Completed at",
    status: "Status",
    lessons_completed: "Number of completed lessons"
  },
  course_statuses: {
    completed: "Completed",
    in_progress: "In progress"
  },
  delete_category: {
    modal_title: "Delete category",
    modal_text: "Are you sure you want to delete the category with all of its content?",
  }
};
