/**
 * Routes of service
 *
 * All routes must be use layout component on first level (with path "/")
 * Root of service index component must have redirect to service index page (it may any name - not only index)
 */

export default [
  {
    path: "/",
    redirect: { name: "courses.all" },
    name: "root",
    components: {
      default: () => import("@/view/layout/Layout"),
    },
    children: [
      {
        path: "/",
        components: {
          default: () => import("./layouts/LmsLayout"),
          "title-actions": () => import("./components/ActionsButton.vue"),
          //search: () => import("./components/Searchbar"),
        },
        children: [
/*           {
            path: "/",
            name: "lms.index",
            component: () => import("./views/Main.vue")
          }, */
                    {
            path: "/",
            name: "courses.all",
            component: () => import("./views/CoursesAll")
          },
          {
            path: "/familiarization-courses",
            name: "courses.familiarization",
            component: () => import("./views/CoursesToFamiliarization")
          },
          {
            path: "/course/new",
            name: "course.new",
            component: () => import("./views/CourseCreate.vue")
          },
          {
            path: "/course/import",
            name: "course.import",
            component: () => import("./views/CourseImport.vue")
          },
          {
            path: "/course/edit/:id",
            name: "course.edit",
            component: () => import("./views/CourseEdit.vue"),
            props: true
          },
          {
            path: "category/new",
            name: "category.new",
            component: () => import("./views/CategoryEdit"),
          },
          {
            path: "category/edit/:id",
            name: "category.edit",
            component: () => import("./views/CategoryEdit"),
            props: true
          },
          {
            path: "category/:id",
            name: "category.show",
            component: () => import("./views/CategoryView"),
            props: true
          },
          {
            path: "categories",
            name: "categories.list",
            component: () => import("./views/CategoriesList")
          },
          {
            path: "courses/user/:id",
            name: "courses.user",
            component: () => import("./views/CoursesUser"),
            props: true
          },
          {
            path: "courses",
            name: "courses.all",
            component: () => import("./views/CoursesAll")
          },
          {
            path: "courses-draft",
            name: "courses.draft",
            component: () => import("./views/CoursesNotPublished")
          },
          {
            path: "courses-archive",
            name: "courses.archive",
            component: () => import("./views/CoursesNotPublished")
          },
          {
            path: "/course/:id",
            name: "course.view",
            component: () => import("./views/CourseView"),
            props: true
          },
          {
            path: "/course/:course_id/lesson/:lesson_id",
            name: "lesson.view",
            component: () => import("./views/LessonView"),
            props: true
          },
          {
            path: "/courses/statistics",
            name: "courses.statistics",
            component: () => import("./views/Statistics"),
          }
        ]
      },
      {
        path: "/help",
        component: () => import("./views/Help.vue"),
        name: "help"
    },
    ]
  },
  {
    path: "/course/scorm/:id",
    name: "course.scorm",
    component: () => import("./views/Scorm.vue"),
  }
];
